const crossellSwiper = new Swiper(".crossell-swiper", {
  slidesPerView: "auto",
  spaceBetween: 12,
  centeredSlides: true, // Default is false for larger screens
  initialSlide: 1,

  breakpoints: {
    // Define the breakpoint for mobile devices
    767: {
      slidesPerView: 3,
      centeredSlides: false,
      spaceBetween: 16,
    },
  },
});
