// var swiper = new Swiper(".feature-card-swiper", {
//   loop: false, // Optional: Set to true if you want infinite scrolling
//   spaceBetween: 12, // Adjust spacing between slides
//   centeredSlides: true, // Ensures it does not center by default
//   slidesPerView: 1.15, // Default for larger screens
//   initialSlide: 1,
//   simulateTouch: false,
//   breakpoints: {
//     768: {
//       // Tablets and larger screens
//       slidesPerView: 1, // Show more slides
//       initialSlide: 0, // Start from the first slide
//     },
//   },
//   navigation: {
//     nextEl: ".swiper-button-next",
//     prevEl: ".swiper-button-prev",
//   },
//   keyboard: {
//     enabled: true,
//   },
// });
var swiper = new Swiper(".feature-card-swiper", {
  loop: false, // Ingen infinite scroll (bra för tillgänglighet)
  spaceBetween: 12,
  centeredSlides: true,
  slidesPerView: 1.15,
  initialSlide: 1,
  simulateTouch: false, // Tillåter textmarkering

  breakpoints: {
    768: {
      slidesPerView: 1.1,
    },
    1024: {
      slidesPerView: 1,
      initialSlide: 0,
    },
  },

  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },

  keyboard: {
    enabled: true,
    onlyInViewport: true, // Tangentbordsstyrning påverkar endast synliga sliders
  },

  a11y: {
    enabled: true,
    prevSlideMessage: "Föregående slide",
    nextSlideMessage: "Nästa slide",
    firstSlideMessage: "Detta är den första sliden",
    lastSlideMessage: "Detta är den sista sliden",
    paginationBulletMessage: "Gå till slide {{index}}",
  },

  watchSlidesProgress: true,
  slideVisibleClass: "swiper-slide-visible", // Hjälper skärmläsare att identifiera aktiv slide

  grabCursor: false, // Hindrar "grab"-ikonen så tangentbordsnavigering fungerar smidigare
});
